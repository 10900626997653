import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import spotify from "../../assets/images/spotify.png";
import apple_music from "../../assets/images/apple-music.png";
import youtube_music from "../../assets/images/youtube_music.png";
import deezer from "../../assets/images/deezer.svg";
import amazon_music from "../../assets/images/amazon-music.png";
import youtube from "../../assets/images/youtube.png";
import audiomack from "../../assets/images/audiomack.png";
import tidal from "../../assets/images/tidal.png";
import soundcloud from "../../assets/images/soundcloud.png";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

function PlaylistPage(props) {
  return (
    <>
      <Helmet>
        <title>Daniel Ospid - {props.title}</title>
        <meta
          name="description"
          content={props.title + " ♡ Disponible Ahora"}
        />
        <link
          rel="canonical"
          href={"https://music.danielospipd.com/" + props.path}
        />
        {/* property */}
        <meta property="og:locale" content="es_SV" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"Daniel Ospid - " + props.title} />
        <meta
          property="og:description"
          content={props.title + " ♡ Disponible Ahora"}
        />
        <meta
          property="og:url"
          content={"https://music.danielospid.com/" + props.path}
        />
        <meta property="og:site_name" content="Daniel Ospid" />
        <meta property="og:image" content={props.cover} />
        <meta property="og:image:alt" content={props.cover_alt} />
        <meta property="og:image:width" content="924" />
        <meta property="og:image:height" content="924" />
        <meta property="og:image:type" content="image/png" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@danielospid" />
        <meta name="twitter:creator" content="@danielospid" />
        <meta name="twitter:title" content={"Daniel Ospid - " + props.title} />
        <meta
          name="twitter:description"
          content={props.title + " ♡ Disponible Ahora"}
        />
        <meta name="twitter:image" content={props.cover} />
      </Helmet>
      <div
        className="music-release-background"
        style={{ backgroundImage: `url(${props.cover})` }}
      ></div>
      <div className="music-release-glass"></div>
      <div className="music-release-container">
        <div className="music-release-content">
          {/* cover */}
          {props.cover && (
            <div className="music-release-cover">
              <img src={props.cover} alt={props.cover_alt} />
            </div>
          )}
          {/* box */}
          <div className="music-release-box">
            {props.title && <h2>{props.title}</h2>}
            <h3>
              Daniel Ospid
              {props.artists && ", " + props.artists}
            </h3>
          </div>
          {/* links */}
          <div className="music-release-links">
            {/* Spotify */}
            {props.spotify && (
              <a href={props.spotify} target="_blank" rel="noopener noreferrer">
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={spotify} alt="Spotify - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* Apple Music */}
            {props.apple_music && (
              <a
                href={props.apple_music}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={apple_music} alt="Apple Music - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* Tidal */}
            {props.tidal && (
              <a href={props.tidal} target="_blank" rel="noopener noreferrer">
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={tidal} alt="Tidal - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* Amazon Music */}
            {props.amazon_music && (
              <a
                href={props.amazon_music}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={amazon_music} alt="Amazon Music - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* YouTube Music */}
            {props.youtube_music && (
              <a
                href={props.youtube_music}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="item-music">
                  <div className="item-music-logo">
                    <img
                      src={youtube_music}
                      alt="YouTube Music - Logo"
                      style={{ height: 30 + "px" }}
                    />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* Deezer */}
            {props.deezer && (
              <a href={props.deezer} target="_blank" rel="noopener noreferrer">
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={deezer} alt="Deezer - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* SoundCloud */}
            {props.soundcloud && (
              <a
                href={props.soundcloud}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={soundcloud} alt="SoundCloud - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* Audiomack */}
            {props.audiomack && (
              <a
                href={props.audiomack}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={audiomack} alt="Audiomack - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
            {/* YouTube */}
            {props.youtube && (
              <a href={props.youtube} target="_blank" rel="noopener noreferrer">
                <div className="item-music">
                  <div className="item-music-logo">
                    <img src={youtube} alt="Youtube - Logo" />
                  </div>
                  <div className="item-music-action">
                    <span className="icon-item">
                      <span className="icon-svg">
                        <FontAwesomeIcon icon={faPlay} />
                        <span className="icon-svg-shadow"></span>
                      </span>
                      <span className="icon-text">Play</span>
                    </span>
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default PlaylistPage;
