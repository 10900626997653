import { faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function NoFound() {
  return (
    <div className="no-found">
      <div className="wrapper">
        <span className="title-no-found">404</span>
        <span className="icon-no-found">
          <FontAwesomeIcon icon={faHeartBroken} />
        </span>
        <p className="text-no-found">
          La página que estas buscando
          <br /> no existe o se ha modificado.
        </p>
        <Link to="/" className="button primary-button">
          Volver al Inicio
        </Link>
      </div>
    </div>
  );
}
export default NoFound;
