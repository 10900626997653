import { db } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { useMusic, usePlaylists } from "../../firebase/fbQueries";
import NoFound from "./NoFound";
import MusicPage from "./MusicPage";
import PlaylistPage from "./PlaylistPage";

function Music() {
  const { path } = useParams();
  
  const music = useMusic(db);
  const musicSelected = music.find((music) => music.path === path);
  
  const playlist = usePlaylists(db);
  const playlistSelected = playlist.find((playlist) => playlist.path === path);

  return (
    <main className="main">
      <section className="section">
        <div className="container">
          {musicSelected ? (
            <MusicPage {...musicSelected} />
          ) : playlistSelected ? (
            <PlaylistPage {...playlistSelected} />
          ) : (
            <NoFound />
          )}
        </div>
      </section>
    </main>
  );
}
export default Music;
